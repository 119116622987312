
@font-face {
  font-family: 'robotoRegular';
  src: url('./assests/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'robotoMedium';
  src: url('./assests/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'robotoBold';
  src: url('./assests/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
}

h1{
  font-family: 'robotoBold';
}

h3{
  font-family: 'robotoMedium';
}

h4{
  font-family: 'robotoBold';
}
h5{
  font-family: 'robotoMedium';
}
p{
  font-family: 'robotoRegular';
}

*{
  padding: 0px;
  margin:0px
}

